<template>
  <div class="d-flex justify-center pa-2 mt-4 full-width">
    <grid item-min-width="400px">
      <v-card>
        <v-card-title>
          {{type}}
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column">
            <div class="d-flex flex-column">
              <v-text-field
                class=""
                :label="$t('t.Number')"
                :value="number"
                readonly
              />
              <v-text-field
                class=""
                :label="$t('t.StartAt')"
                :value="startAt"
                readonly
              />
              <div
                v-if="launchBy"
                class="d-flex flex-column"
              >
                <span class="caption theme--dark v-label mb-1">{{toPascalCase($t('t.By'))}}</span>
                <user-ref
                  block-popup
                  hide-group
                  hide-email
                  hide-phone
                  :avatar-props="{xsmall: true}"
                  :id="launchBy"
                />
              </div>
            </div>

            <v-progress-circular
              class="progress"
              :rotate="360"
              :size="180"
              :width="18"
              :value="progress"
              color="primary"
            >
              <span class="text-h4">{{progress}}%</span>
            </v-progress-circular>
            <div
              v-if="!isProcessing"
              class="d-flex flex-column align-center justify-center mt-8"
            >
              <span>{{$t('t.NavigatingToResult')}}...</span>
              <progress-timer
                :timeout="2000"
                color="secondary"
                @timer-done="navigate(id)"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </grid>
  </div>
</template>

<script>

import navigation from '@/navigationController'

export default {
  components: {
    Grid: () => import('@/components/grid'),
    ProgressTimer: () => import('./progress-timer'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  deactivated () {
    this.$removeFromKeepAliveCache()
    this.$destroy()
  },
  mounted () {
    this.$store.commit('setModule', {
      name: this.$t('t.Processing'),
      icon: 'i.Processing'
    })

    this.setLoop()
  },
  computed: {
    startAt () {
      return this.formatDateTime(this.startingDate)
    }
  },
  data () {
    return {
      id: null,
      isProcessing: true,
      launchBy: null,
      loopHandler: undefined,
      number: null,
      progress: 0,
      startingDate: null,
      type: null
    }
  },
  methods: {
    setLoop () {
      this.getData()
      this.loopHandler = setInterval(this.getData, 3000) // 3 min
    },
    async navigate (id) {
      await navigation.navigateTo(id, 'runs', undefined, true)
    },
    async getData () {
      const r = await this.$http().get('/core/run/status/' + this.$route.params.id)
      this.id = r?.data?.id
      this.isProcessing = r?.data?.isProcessing
      this.launchBy = r?.data?.launchBy
      this.number = r?.data?.number
      this.progress = r?.data?.progress ?? 0
      this.startingDate = r?.data?.startingDate
      this.type = r?.data?.type

      if (!this.isProcessing) {
        clearInterval(this.loopHandler)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.progress
  margin-top 20px
  margin-left auto
  margin-right auto
</style>
